import React, { Fragment } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Banner from 'components/Banner'
import Features from 'components/Features'
import Contacts from 'components/Contacts'
import FeedbackButton from 'components/FeedbackButton'
import FAQ from 'components/FAQ'
import bem from 'bem'
import css from './Partner.module.scss'
import Button from 'components/Button'
import useSiteMetadata from 'hooks/useSiteMetadata'
import Profit from './components/Profit'
import How from './components/How'
import Calc from './components/Calc'
import Stepper from 'components/Stepper'
import Explanations from 'components/Explanations'
import Cube from 'components/Cube'
import Figure from 'components/Figure'
import PseudoLink from 'components/PseudoLink'
import Link from 'gatsby-link'

const b = bem.scenePartner(css)

const Partner = ({ images }) => {
	const { partnerUrl } = useSiteMetadata()
	const pictures = {}
	images.allFile.edges.forEach(({ node }) => {
		pictures[node.name] = node.childImageSharp.fluid
	})
	const registerUrl = partnerUrl + '/sign-up'

	return (
		<Layout className={b()}>
			<SEO
				title="Партнерская программа — «Парсик»"
				description="Выплачиваем до 30% от прибыли. Рассказывайте о нас и получайте процент с продаж баз компаний и парсинга"
				keywords="партнерская программа, партнерская, программа, партнер, реферал, выплаты, прибыль, деньги"
			/>
			<Banner
				title="Давайте работать вместе"
				description="Выплаты до <strong>30%</strong> от прибыли"
				image={{ name: 'home', width: 506, height: 392 }}
			>
				<Button isLink to={registerUrl} title="Партнерская программа" size="lg" theme="light">
					Стать партнером
				</Button>
			</Banner>
			<Sector className="pb-0" title="Зарабатывайте вместе с нами" titleCentered>
				<Grid fluid className="mb-xl">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<p className="fs-h4">
								Рассказывайте о&nbsp;нас в&nbsp;интернете и&nbsp;получайте процент с&nbsp;продаж{' '}
								<Link to="/dbs.html#list" title="Базы компаний">
									баз&nbsp;компаний
								</Link>{' '}
								и&nbsp;
								<Link to="/" title="Парсинг сайтов">
									парсинга сайтов
								</Link>
							</p>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Features
						columns={3}
						isSector={false}
						data={[
							{
								url: '/',
								icon: 'chart',
								title: 'Отличная конверсия',
								description: 'Парсинг и&nbsp;базы компаний имеют большой интерес в&nbsp;наше время',
							},
							{
								url: '/',
								icon: 'fire',
								title: 'До 30% отчислений',
								description:
									'Высокая прибыль&nbsp;<strong>25%</strong> с&nbsp;продажи баз, <strong>30%</strong> с&nbsp;парсинга',
							},
							{
								url: '/',
								icon: 'cards',
								title: 'Выплаты без задержек',
								description: 'Вывод средств в&nbsp;любой день месяца на&nbsp;карту и&nbsp;электронный кошелек',
							},
						]}
					/>
				</div>
			</Sector>
			<Profit />
			<How pictures={pictures} />
			<Calc />
			<Sector title="Как стать партнером" titleCentered>
				<Grid fluid className={b('stepper-description')}>
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<p className="fs-h4 text-center">Все предельно просто</p>
						</Col>
					</Row>
				</Grid>

				<Stepper
					data={[
						{
							title: 'Регистрация',
							text: (
								<Fragment>
									<p>
										Зарегистрируйтесь с&nbsp;помощью действующего email-адреса. Мы&nbsp;отправим вам письмо
										с&nbsp;подтверждением и&nbsp;предоставим личный кабинет
									</p>
									<p className="mb-sm">Регистрация не&nbsp;является чем-то особенным</p>
									<Button isLink to={registerUrl} title="Партнерская программа" size="sm" theme="light">
										Зарегистрироваться
									</Button>
								</Fragment>
							),
							image: 'partner-1',
							alt: 'Регистрация в партнерской программе',
						},
						{
							title: 'Получение ссылки',
							text: (
								<p>
									По&nbsp;вашим партнерским ссылкам вы&nbsp;будете приводить клиентов на&nbsp;сайт и&nbsp;получать
									прибыль. Вы&nbsp;можете создать партнерскую ссылку на&nbsp;любую страницу сайта
								</p>
							),
							image: 'partner-2',
						},
						{
							title: 'Размещение ссылок',
							text: (
								<Fragment>
									<p>Для размещения ссылок вы&nbsp;можете использовать разные площадки:</p>
									<ul>
										<li>Социальные сети</li>
										<li>Сайты и&nbsp;блоги</li>
										<li>Форумы с&nbsp;обсуждениями и&nbsp;дискуссиями</li>
										<li>Интернет-порталы</li>
									</ul>
									<p>Чем больше будет переходов по&nbsp;ссылкам, тем больше будет клиентов и&nbsp;ваша прибыль</p>
								</Fragment>
							),
							image: 'partner-3',
						},
						{
							title: 'Получение прибыли',
							text: (
								<Fragment>
									<p>
										Ожидайте переходов по&nbsp;вашим ссылкам и&nbsp;получайте прибыль с&nbsp;каждого приглашенного
										клиента.
									</p>
									<p>Выводите денежные средства на&nbsp;банковскую карту или электронные кошельки</p>
								</Fragment>
							),
							image: 'partner-4',
						},
					]}
				/>
			</Sector>

			<Sector color="gray" isGradient>
				<h2 className="mb md_mb-lg">Как находить клиентов?</h2>
				<Grid fluid className={b({})}>
					<Cube color="white">
						<Row>
							<Col lgOffset={1} lg={10}>
								<Explanations
									items={[
										{
											title: 'Сайты, блоги, форумы',
											text: (
												<p>
													Разместите статью на гостевых сайтах. Хорошие статьи набирают сотни клиентов. Пишите
													комментарии и посты на форумах и блогах
												</p>
											),
										},
										{
											title: 'Социальные сети',
											text: (
												<p>
													Создайте рекламный пост и предложите его подписчикам в своем сообществе и в других
													сообществах. Используйте все возможные социальные сети
												</p>
											),
										},
										{
											title: 'Свой сайт',
											text: <p>Предлагайте наши услуги посетителям на своем сайте и получайте постоянную прибыль</p>,
										},
										{
											title: 'Знакомые и друзья',
											text: (
												<p>
													Предложите своим знакомым стать партнерами. Получайте повышенный процент за привлечение
													каждого нового партнера
												</p>
											),
										},
									]}
								/>
							</Col>
						</Row>
					</Cube>
				</Grid>
			</Sector>

			{/* eslint-disable react/jsx-no-target-blank */}
			<Sector className="mb-lg" title="Вопросы и ответы" titleCentered>
				<Row className="text-center mb-lg text-lg">
					<Col lgOffset={1} lg={10}>
						<p>Задайте свой вопрос ниже, если не нашли ответа</p>
					</Col>
				</Row>
				<div className="mb-lg">
					<FAQ
						currentIndexes={[0]}
						isNumbers={true}
						items={[
							{
								title: 'Как стать участником партнерской программы?',
								description: (
									<div className="p-no-last">
										<p>Чтобы стать нашим партнером:&nbsp;</p>
										<ol>
											<li>
												Ознакомьтесь с&nbsp;
												<a href="/partner-conditions.html" title="Условия участия в партнерской программе">
													условиями участия в&nbsp;партнерской программе
												</a>
												.&nbsp;
											</li>
											<li>Зарегистрируйтесь в&nbsp;системе, используя действующий email-адрес.&nbsp;</li>
											<li>
												Активируйте партнерский аккаунт по&nbsp;ссылке, отправленной на&nbsp;ваш email-адрес.&nbsp;
											</li>
										</ol>
										<p>После этого ваш аккаунт полностью готов к&nbsp;работе.&nbsp;</p>
									</div>
								),
							},
							{
								title: 'Какие условия работы с&nbsp;партнерской программой?',
								description: (
									<div className="p-no-last">
										<p>
											Партнеры обязаны соблюдать{' '}
											<a href="/partner-conditions.html" title="Условия участия в партнерской программе">
												условия участия в&nbsp;партнерской программе
											</a>
											. Внимательно ознакомьтесь с&nbsp;условиями.&nbsp;
										</p>
										<p>
											При возникновении нарушений возможно как обычное предупреждение, так и&nbsp;полная блокировка
											аккаунта. Все нарушения условий рассматриваются индивидуально.&nbsp;
										</p>
									</div>
								),
							},
							// prettier-ignore
							{
								title: 'В&nbsp;чем заключается работа партнера?',
								description: (
									<div className="p-no-last">
										<p>Весь процесс работы предельно прост:</p>
										<ol>
											<li>Вы&nbsp;рекламируете наш сайт в&nbsp;интернете с&nbsp;помощью партнерских ссылок</li>
											<li>По&nbsp;этим ссылкам приходят клиенты и&nbsp;заказывают наши услуги</li>
											<li>Мы&nbsp;обслуживаем клиентов, выполняем услуги</li>
											<li>Вам отчисляется процент от&nbsp;прибыли</li>
										</ol>
										<p>Партнерские ссылки вы&nbsp;можете найти <a href="https://partner.parsic.ru/direct/links" target="_blank" title="Партнерские ссылки">в&nbsp;личном кабинете</a> после <a href="https://partner.parsic.ru/sign-up" target="_blank" title="Регистрация в партнерской программе">регистрации</a>. Размещайте их&nbsp;на&nbsp;сайтах, блогах, в&nbsp;социальных сетях и&nbsp;других местах в&nbsp;интернете, соблюдая условия участия.</p>
										<p>Чем больше ссылок будет размещено, тем больше вы&nbsp;получите рефералов&nbsp;и, как следствие, больше прибыли.</p>
									</div>
								),
							},
							// prettier-ignore
							{
								title: 'Как рекламировать услуги?',
								description: (
									<div className="p-no-last">
										<div className="mb">Существует много способов рекламы услуг в интернете</div>
										<div className="mb-lg">
											<h4 className="mb">На других сайтах</h4>
											<p>Это самый выгодный партнерам способ рекламы. Мы&nbsp;знаем, что продвигать услуги на&nbsp;сайтах сложнее, поэтому поощряем партнеров и&nbsp;выплачиваем больший процент за&nbsp;таких клиентов.</p>
											<p>Чтобы рекламировать услуги на&nbsp;других сайтах, нужно писать и&nbsp;публиковать статьи с&nbsp;вашими партнерскими ссылками. Важно, чтобы эти статьи были вашими и&nbsp;не&nbsp;скопированы из&nbsp;других источников.</p>
											<p>Качественные статьи собирают тысячи читателей и&nbsp;сотни клиентов. Например, <PseudoLink className="button-link" to="https://vc.ru/services/115584-30-besplatnyh-programm-dlya-parsinga-saytov-v-2020-godu">эта статья</PseudoLink> была просмотрена более <strong>150&nbsp;000&nbsp;раз</strong>. Если хотя&nbsp;бы 1&nbsp;из&nbsp;200 человек сделает заказ, то&nbsp;статья наберет <strong>750&nbsp;клиентов</strong>. Если с&nbsp;каждого клиента вы&nbsp;получите&nbsp;700&nbsp;руб., то&nbsp;итоговая прибыль составит <strong>525&nbsp;000&nbsp;руб</strong>.</p>
											<p>Чтобы ваша статья была эффективной и&nbsp;ее&nbsp;читатели становились клиентами, нужно писать качественные статьи, которые подробно раскрывают тему и&nbsp;ненавязчиво рекламируют услугу.</p>
											<p>Вы&nbsp;можете писать статьи самостоятельно или заказывать их&nbsp;на&nbsp;бирже у&nbsp;экспертов. <PseudoLink className="button-link" to="https://workinnet.ru/copywriting-services-for-beginners/">Вот список бирж</PseudoLink></p>
											<p><PseudoLink className="button-link" to="https://news.pressfeed.ru/luchshie-resursy-dlya-besplatnogo-razmeshheniya-statej/">Вот список сайтов</PseudoLink>, на&nbsp;которых можно бесплатно разместить статью с&nbsp;партнерской ссылкой.</p>
											<p>Чтобы <PseudoLink className="button-link" to="https://www.google.com/search?q=%22%D0%BF%D1%80%D0%B8%D0%BD%D0%B8%D0%BC%D0%B0%D1%8E+%D0%B3%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D1%8B%D0%B5+%D0%BF%D0%BE%D1%81%D1%82%D1%8B%22">найти еще сайты</PseudoLink>, принимающие гостевые посты, введите в&nbsp;поисковой системе "принимаю гостевые посты" (с&nbsp;кавычками)</p>
											<Figure
												fluid={pictures['guest-posting']}
												caption="Поиск сайтов, принимающих гостевые посты"
												alt="Поиск сайтов, принимающих гостевые посты с партнерскими ссылками"
												title="Поиск сайтов, принимающих гостевые посты с партнерскими ссылками"
												placeholderStyle={{ visibility: 'hidden' }}
												isLink
												isMargin
											/>
										</div>
										<div className="mb-lg">
											<h4 className="mb">На&nbsp;форумах и&nbsp;блогах</h4>
											<p>Оставляйте партнерские ссылки на&nbsp;форумах и&nbsp;в&nbsp;комментариях разных блогов. Часто люди спрашивают в&nbsp;интернете решение своей проблемы и&nbsp;вы&nbsp;можете порекомендовать им&nbsp;наши услуги. Этот метод так&nbsp;же эффективен для привлечения клиентов.</p>
											<p>Вы&nbsp;можете создавать собственные форумы и&nbsp;обсуждения и&nbsp;публиковать партнерские ссылки на&nbsp;них.</p>
											<p>Чем больше ссылок оставите на&nbsp;разных площадках, тем больше будет переходов по&nbsp;ним и&nbsp;тем больше будет заказов и&nbsp;прибыли.</p>
										</div>
										<div className="mb-lg">
											<h4 className="mb">В&nbsp;социальных сетях</h4>
											<p>Социальные сети имеют огромную аудиторию и&nbsp;это очень хороший способ рекламы услуг.</p>
											<p>Так как у&nbsp;нас B2B-услуги, то&nbsp;для публикации лучше выбирать социальные сети, имеющие аудиторию, склонную к&nbsp;бизнесу. Например, тик-ток и&nbsp;одноклассники подходят хуже для этих целей, а&nbsp;инстаграм, фейсбук подойдут отлично.</p>
											<p>Вы&nbsp;можете рассказывать об&nbsp;услугах на&nbsp;своей странице, в&nbsp;своем сообществе или в&nbsp;других сообществах, имеющих схожую с&nbsp;услугами тематику.</p>
											<p>Платное размещение в&nbsp;бизнес-сообществах дает наилучший эффект.</p>
										</div>
										<div className="mb-lg">
											<h4 className="mb">На&nbsp;своем сайте</h4>
											<p>Это один из&nbsp;лучших методов получения клиентов. Вы&nbsp;можете публиковать партнерские ссылки и&nbsp;промо-материалы на&nbsp;своем личном сайте. Мы&nbsp;подготовили для этого программный код для вставки с&nbsp;баннерами, привлекающими внимание.</p>
											<Figure
												fluid={pictures['code']}
												caption="Программный код для вставки с баннеров с партнерской ссылкой на свой сайт"
												alt="Программный код для вставки с баннеров с партнерской ссылкой на свой сайт"
												title="Программный код для вставки с баннеров с партнерской ссылкой на свой сайт"
												placeholderStyle={{ visibility: 'hidden' }}
												isLink
												isMargin
											/>
											<p>Выберите подходящий по&nbsp;размеру баннер, скопируйте код и&nbsp;вставьте на&nbsp;свой сайт</p>
										</div>
										<div className="mb-lg">
											<h4 className="mb">Прямая продажа</h4>
											<p>Продавать можно компаниям напрямую.</p>
											<p>Простейший пример: партнер находит сайт, предлагающий услуги по&nbsp;ремонту и&nbsp;отделке. Созванивается с&nbsp;компанией и&nbsp;предлагает заказать базу строительных компаний для email-рассылок. Если клиент соглашается, вы&nbsp;отправляете ему на&nbsp;почту вашу партнерскую ссылку.</p>
											<p>Метод хорошо подойдет людям, умеющим общаться с&nbsp;клиентами.</p>
										</div>
									</div>
								)
							},
							{
								title: 'Как начисляются деньги на&nbsp;счет?',
								description: (
									<div className="p-no-last">
										<p>Деньги на&nbsp;ваш счет начисляются после выполнения следующих действий:&nbsp;</p>
										<ol>
											<li>
												Человек пришел на&nbsp;наш сайт по&nbsp;вашей партнерской ссылке из&nbsp;другого
												источника.&nbsp;
											</li>
											<li>
												<p>Этот человек заказывает услугу.&nbsp;</p>
												<p>
													— Если это покупка базы компаний, то&nbsp;после получения денежных средств от&nbsp;клиента,
													вы&nbsp;сразу&nbsp;же получаете комиссионные на&nbsp;ваш баланс.&nbsp;
												</p>
												<p>
													— Если это парсинг, то&nbsp;ваши комиссионные начисляются после выполнения работ. Разработка
													парсинга может выполняться от&nbsp;1&nbsp;до&nbsp;14&nbsp;дней.&nbsp;
												</p>
											</li>
										</ol>
									</div>
								),
							},
							{
								title: 'Как вывести деньги из&nbsp;партнерской программы?',
								description: (
									<div className="p-no-last">
										<p>Чтобы вывести деньги:</p>
										<ol>
											<li>
												Перейдите на&nbsp;страницу &laquo;Финансы&raquo;{' '}
												<a
													href="https://partner.parsic.ru/cabinet/finances"
													target="_blank"
													title="Страница Финансы партнерской программы"
												>
													https://partner.parsic.ru/cabinet/finances
												</a>
											</li>
											<li>Выберите способ вывода и&nbsp;укажите реквизиты карты или яндекс кошелька</li>
											<li>Нажмите кнопку &laquo;Вывести&raquo;</li>
										</ol>
										<Figure
											fluid={pictures['finances']}
											caption="Вывод денежных средств из партнерской программы"
											alt="Вывод денежных средств из партнерской программы"
											title="Вывод денежных средств из партнерской программы"
											placeholderStyle={{ visibility: 'hidden' }}
											isLink
											isMargin
										/>
										<p>
											Средства поступят в&nbsp;течение 3х&nbsp;рабочих дней, но&nbsp;обычно, начисляются
											в&nbsp;тот&nbsp;же день.
										</p>
										<p>
											Вы&nbsp;можете выводить денежные средства в&nbsp;любой день месяца. Минимальная сумма
											вывода&nbsp;&mdash; 500&nbsp;рублей.
										</p>
									</div>
								),
							},
							{
								title: 'Что такое партнерская ссылка?',
								description: (
									<div className="p-no-last">
										<p>
											<strong>Партнерская ссылка</strong>&nbsp;&mdash; это индивидуальная ссылка
											с&nbsp;идентификационным номером партнера. В&nbsp;результате переходов по&nbsp;этой ссылке партнер
											получает рефералов, а&nbsp;мы&nbsp;понимаем, какому партнеру начислять денежные средства.&nbsp;
										</p>
										<p>
											Для привлечения клиентов на&nbsp;сайт, необходимо использовать именно партнерскую ссылку.
											Вы&nbsp;можете сгенерировать партнерскую ссылку на&nbsp;любую страницу нашего сайта с&nbsp;личном
											кабинете.&nbsp;
										</p>
										<p>
											Ваша партнерская ссылка доступна{' '}
											<a href="https://partner.parsic.ru/direct/links" target="_blank" title="Партнерские ссылки">
												в личном кабинете
											</a>
										</p>
										<Figure
											fluid={pictures['link']}
											caption="Пример партнерской ссылки"
											alt="Пример партнерской ссылки"
											title="Пример партнерской ссылки"
											placeholderStyle={{ visibility: 'hidden' }}
											isLink
											isMargin
										/>
									</div>
								),
							},
							{
								title: 'Что такое реферал?',
								description: (
									<div className="p-no-last">
										<p>
											<strong>Реферал</strong>&nbsp;&mdash; это человек, который перешел на&nbsp;наш сайт по&nbsp;вашей
											партнерской ссылке.&nbsp;
										</p>
										<p>
											В&nbsp;нашей системе отображается информация о&nbsp;том, с&nbsp;какого ip-адреса перешел человек,
											с&nbsp;какого сайта, на&nbsp;какую страницу и&nbsp;в&nbsp;какое время.&nbsp;
										</p>
									</div>
								),
							},
						]}
					/>
				</div>
				<blockquote className="blockquote mb-lg">
					Если у&nbsp;вас остались вопросы по&nbsp;партнерской программе, нажмите кнопку &laquo;Задать вопрос&raquo;
					и&nbsp;задайте его в&nbsp;форме обратной связи
				</blockquote>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Задать вопрос
					</FeedbackButton>
				</div>
			</Sector>

			<Contacts color="gray" />
		</Layout>
	)
}

export default Partner
